import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/core/services/SharedService.service';
import { TenantsettingsService } from 'src/app/core/services/tenantsettings.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

/**
 * Footer component
 */
export class FooterComponent implements OnInit {
  version:string;
  token_response:any;
  tenantName:string;
  timeZone:string;
  location:string;
  // set the currenr year
  year: number = new Date().getFullYear();
  profilePicture: any;

  constructor(public tenantsettingsService:TenantsettingsService,private sharedService: SharedService) { }

  ngOnInit() {
    this.sharedService.getafterheaderimage().subscribe((data) => {
      this.GetTenantData();
      });
      this.GetTenantData();   
    this.token_response = JSON.parse(localStorage.getItem("siteaudit_token_response") || '{}');
    this.tenantName = this.token_response.TenantName;
    this.location = this.token_response.CreatedUserLocation;
    this.timeZone = this.token_response.CreatedUserTimeZone;
    this.version = environment.Version;
  }
  GetTenantData(){
    this.tenantsettingsService.GetTenantSettingsByTenantId().subscribe((result)=>{
      console.log(result,'GetTenantData');
      // this.profilePicture=result.SiteLogo;
      if (result.SiteLogo == '../../../assets/images/th.jpg') {
        this.profilePicture = null
      }
      else if (result.SiteLogo == '../../../assets/images/siteauditlogo-sm.png') {
        this.profilePicture = null
      }
      else {
        this.profilePicture = result.SiteLogo;
      }      
    // if(result.SiteLogo!=null && result.SiteLogo!=undefined && result.SiteLogo!=""){
    //   this.profilePicture=result.SiteLogo;
    // }
    // else{
    //   this.profilePicture="../../../assets/images/siteauditlogo-sm.png"
    // } 
    console.log( this.profilePicture);
    });
    }
}
